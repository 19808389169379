<template>
	<div>
		<common-table
				:itemScope="['lsell', 'ticketType']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				:listDeploy="listDeploy"
				pageTitle="票务大类"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
				@beforeOpenAddDialog="beforeOpenAddDialog"
				@otherRightOperate="otherRightOperate"
		>
			<template v-slot:lsell="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lsell" hide-details dense/>
			</template>

			<template v-slot:ticketType="{ item }">
				<v-btn depressed color="primary" @click="ticketType(item.code)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-fangxing</v-icon>
					票型
				</v-btn>
			</template>
		</common-table>

		<template v-if="qrObject.dialog">
			<qr-code
					:dialog.sync="qrObject.dialog"
					:param="qrObject.param"
					:subtitle="qrObject.subtitle"
					:pathNo="qrObject.pathNo"
			/>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/ticket.js';
import rangeTimePicker from "@/components/timePicker/rangeTimePicker.vue";
import editorText from "@/components/editorText/editorText.vue";
import qrCode from "@/components/qr-code/qr-code.vue"
import rangeTime from '@/components/dateTime/rangeTime.vue';

export default {
	mixins: [commonCurd],
	components: {
		qrCode
	},
	data() {
		return {
			api,
			qrObject: {
				dialog: false,
				param: '',
				subtitle: ''
			},
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			// 定义右侧按钮
			rightConfig: [
				// {label: '同步票型', icon: 'tongbu', event: 'syncTicketType'},
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			// 定义左侧搜索框
			leftConfig: [
				{type: 'input', label: '输入票务代码/名称', property: 'searchkey', width: '200px'}
			],
			btnActionEvent: [
				{
					event: 'editItem',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑'
				}
				, {
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除'
				},
				{
					event: 'phoneView',
					icon: 'erweima',
					color: 'primary',
					tooltip: '移动端'
				},
				// {
				//   event: 'pcView',
				//   icon: 'diannaoduan',
				//   color: 'primary',
				//   tooltip: '电脑端'
				// }
			],
			// 表格头部字段
			headers: [
				{text: '票务大类代码', value: 'code'},
				// {text: '票务分组', value: 'groupid'},
				{text: '票务大类名称', value: 'description'},
				{text: '包含票型', value: 'ticketType'},
				{text: '排序号', value: 'seq'},
				{text: '预订状态', value: 'lsell'},
				{text: '操作', value: 'actions', width: 120}
			],
			// 表单字段
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "票务大类代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "票务大类名称"
							}
						}
					},
					// {
					// 	cols: 6,
					// 	subassembly: "WAutocomplete",
					// 	property: 'groupid',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			label: "票务分组",
					// 			items: []
					// 		}
					// 	}
					// },
					/*{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'ticketCode',
						connector: {
							props: {
								required: true,
								label: "关联票型",
								items: [],
								multiple: true,
								chips: true
							}
						}
					},*/
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'tel',
						connector: {
							props: {
								required: true,
								label: "联系电话",
								rules: [
									v => !!v || '票务电话不能为空',
									v => (/^1[3|4|5|7|8][0-9]\d{8}$/.test(v) || /^(\d{3,4}-)?\d{7,8}$/.test(v)) || '电话格式不正确',
								],
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'address',
						connector: {
							props: {
								required: true,
								label: "景点地址"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'coordinate',
						connector: {
							props: {
								label: "地图坐标",
								required: true,
								hint: '可在网上搜索“拾取坐标系统”后获取目标坐标',
								persistentHint: true,
							}
						}
					},
					{
						cols: 6,
						subassembly: rangeTimePicker,
						property: 'timeRange',
						connector: {
							props: {
								required: true,
								startText: '开园时间',
								endText: '闭园时间'
							}
						}
					},
					{
						cols: 6,
						subassembly: rangeTime,
						property: ["startsell", "endsell"],
						// 解构函数，应用场景：当一个组件的返回值要应用在多个表单属性上时候使用
						deconstruction: function (args) {
							return args.split(" ~ ")
						},
						connector: {
							props: {
								label: "起售日期 - 截止日期",
								clearable: true
							}
						}
					},
					{
						cols: 6,
						subassembly: rangeTime,
						property: ["startdate", "enddate"],
						// 解构函数，应用场景：当一个组件的返回值要应用在多个表单属性上时候使用
						deconstruction: function (args) {
							return args.split(" ~ ")
						},
						connector: {
							props: {
								label: "有效日期范围",
								clearable: true
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号码",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'alertops',
						disabledDeleteDefault: true,
						connector: {
							props: {
								label: '购买弹窗提示',
								required: true,
								items: [{desc: '不弹窗', code: 0}, {desc: '弹窗', code: 1}, {desc: '弹窗并阅读全部内容', code: 2}],
								multiple: false,
								chips: true
							}
						},
						default: false
					},
					{
						cols: 6,
						subassembly: "WSwitches",
						property: 'lsell',
						disabledDeleteDefault: true,
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					},
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'mlistpic',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			showAddIcon: false,
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '移动端列表图(686*320)',
					// 			fileType: 'ticket'
					// 		}
					// 	}
					// },
					{
						cols: 12,
						subassembly: 'MaterialFile',
						property: 'mslidepics',
						connector: {
							props: {
								required: true,
								subTitle: '点击添加按钮新增上传区域，拖动选中的文件区域可对其排序，' +
										'图片建议：宽高比例4:3，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp，数量上限为6张',
								label: '上传图片',
								title: '应用端轮播图',
								fileType: 'ticket'
							}
						}
					},
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'listpic',
					// 	connector: {
					// 		props: {
					// 			required: false,
					// 			showAddIcon: false,
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '电脑端列表图(1264*713)',
					// 			fileType: 'ticket'
					// 		}
					// 	}
					// },
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'slidepics',
					// 	connector: {
					// 		props: {
					// 			required: false,
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '电脑端轮播图(680*340)',
					// 			fileType: 'ticket'
					// 		}
					// 	}
					// },
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '票务简介'
							}
						}
					},
					{
						cols: 12,
						subassembly: 'VTextarea',
						property: 'openingtext',
						connector: {
							props: {
								solo: true,
								rows: 2,
								outlined: true,
								flat: true
							}
						}
					},
					/*{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '入园信息'
							}
						}
					},
					{
						cols: 12,
						subassembly: editorText,
						property: 'infotext',
						connector: {
							props: {
								multi: 1
							}
						}
					},*/
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title mt-6',
							domProps: {
								innerHTML: '预订须知'
							}
						}
					},
					{
						cols: 12,
						subassembly: editorText,
						property: 'introtext',
						connector: {
							props: {
								multi: 2
							}
						}
					},
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title mt-6',
							domProps: {
								innerHTML: '游玩指南'
							}
						}
					},
					{
						cols: 12,
						subassembly: editorText,
						property: 'guidetext',
						connector: {
							props: {
								multi: 3
							}
						}
					}
				]
			}
		}
	},
	methods: {
		ticketType(item) {
			this.$router.push({name: 'ticketType', query: {value: item}});
		},
		beforeSave(form) {
			if (!this.globalMethods.isEmpty(form.timeRange)) {
				let array = form.timeRange.split(" ~ ");
				form.openingtime = array[0];
				form.closetime = array[1];
			}
		},
		beforeEdit(form) {
			if (!this.globalMethods.isEmpty(form.openingtime) && !this.globalMethods.isEmpty(form.closetime)) {
				form.timeRange = form.openingtime + " ~ " + form.closetime;
			}
		},
		beforeOpenAddDialog() {
			this.getCommonSelect();
		},
		otherTableOperate(event, item) {
			if (event === 'phoneView') {
				this.qrObject = {
					dialog: true,
					param: item.code,
					subtitle: item.description,
					pathNo: 4
				}
			}
		},
		otherRightOperate(event) {
			if (event === this.rightConfig[0].event) {
				this.axios.post(this.syncTicketType).then((res) => {
					if (res.code === this.staticVal.Code.Success) {
						this.snackbar.success('同步成功');
					}
				});
			}
		},
		// 通用下拉框数据
		getCommonSelect() {
			// this.axios.post(
			// 		this.select_data, {keyname: "tickettype,ticket"}
			// ).then((res) => {
			// 			if (res?.data) {
			// 				this.listDeploy.row[2].connector.props.items = res.data[0].values;
			// 				this.listDeploy.row[3].connector.props.items = res.data[1].values;
			// 				if (this.listDeploy.sqlid && this.listDeploy.sqlid !== 0) {
			// 					this.getTicketGroup((group) => {
			// 						this.listDeploy.row[3].connector.props.items = res.data[1].values.concat(group);
			// 					});
			// 				}
			// 			}
			// 		}
			// );
		},
		// 编辑时票型下拉框不显示已经绑定的数据，因此需重新查找出已绑定的数据再加入到未绑定的下拉框
		getTicketGroup(callback) {
			this.axios.post(
					this.select_data, {
						keyname: "ticketgroup",
						params: {key: 'ticketgroup', value: this.listDeploy.row[0].default}
					}
			).then((response) => {
				if (response.code === this.staticVal.Code.Success) {
					if (callback) {
						callback(response.data[0].values[0].children);
					}
				}
			});
		}
	},
	mounted() {
		this.getCommonSelect();
	}
}
</script>

<style scoped lang="scss">

</style>
